<template>
  <div
    v-if="image"
    class="imager"
    :class="{ left: left, right: right }">
    <template v-if="svg">
      <img
        :src="image.url"
        :alt="image.alt"
        :title="image.title.rendered ? image.title.rendered : image.alt" />
    </template>
    <template v-else-if="native">
      <img
        v-lazy="image.url"
        :alt="image.alt"
        :title="image.title.rendered ? image.title.rendered : image.alt"
        :data-srcset="`${
          image.sizes.large ? image.sizes.large : image.url
        } 1024w, ${image.url} ${image.width}w`"
        :sizes="`(max-width: 767) 1024px, ${image.width}px`"
        :src="image.url"
        :width="width ? width : image.width"
        :style="{ width: `${width ? width : image.width}px` }" />
    </template>
    <template v-else>
      <div
        class="imager-container"
        :style="{
          'max-width': `${width ? width : image.media_details.width}px`,
        }">
        <div
          class="placeholder"
          :style="{ 'padding-bottom': `${aspectRatio}%` }">
          <div class="imager">
            <div
              class="imager-container"
              :style="{
                maxWidth: `${width ? width : image.media_details.width}px`,
              }">
              <div
                class="placeholder"
                :style="{ paddingBottom: `${aspectRatio}%` }">
                <img
                  v-lazy="image.url"
                  :alt="image.alt"
                  :title="
                    image.title.rendered ? image.title.rendered : image.alt
                  "
                  :data-srcset="`${
                    image.sizes.large ? image.sizes.large : image.url
                  } 1024w, ${image.url} ${image.width}w`"
                  :sizes="`(max-width: 767) 1024px, ${image.width}px`"
                  :src="image.url"
                  :width="width ? width : image.width"
                  :style="{ width: `${width ? width : image.width}px` }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  image: Object,
  left: Boolean,
  right: Boolean,
  native: Boolean,
})

const width = computed(() => {
  return props.image.width
})

const height = computed(() => {
  return props.image.height
})

const aspectRatio = ref()
const svg = ref(false)

const setDimensions = () => {
  aspectRatio.value = (height.value / width.value) * 100
}

onMounted(() => {
  if (props.image.subtype === 'svg+xml' ? true : false) {
    svg.value = true
  } else {
    setDimensions()
  }
})
</script>

<style lang="scss">
.imager {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .imager-container {
    position: relative;
    flex: 1;

    .placeholder {
      position: relative;
      height: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        max-height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease;

        &.loaded {
        }

        &[lazy='loading'] {
          opacity: 0;
          @include transition;
        }

        &[lazy='error'] {
        }

        &[lazy='loaded'] {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>
