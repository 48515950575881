<template>
  <section class="general-content" :class="{'has-animation': data.general_content_text_animation_toggle}">
    <div class="container">
      <div class="general-content-container" :class="{'has-animation': data.general_content_text_animation_toggle}">
        <div class="headline h3 underline-em" v-html="data.genereal_content_headline" ref="trigger"></div>
        <div class="text text-large editor-formatting orange-em" v-html="data.general_content_text"></div>
      </div>
    </div>
    <div class="gc-anchors" v-if="data.general_content_text_anchors">
      <div class="anchor" v-for="(item, index) in data.general_content_text_anchors" :key="index">
        <a :href="`#${item.general_content_text_anchor}`">
          <p>{{ item.general_content_text_anchor_label }}</p>
        </a>
      </div>
    </div>
    <SplineViewerComponent class="spline" v-if="data.general_content_text_animation_toggle"
      :scene="logo" />
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "GeneralContentComponent",
    "CLASSNAME": "general-content",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import SplineViewerComponent from '@/components/utility/SplineViewerComponent.vue'
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import logo from '@/assets/spline/logo.splinecode?url'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)

let tl

onMounted(() => {

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top center',
      end: 'bottom center',
      scrub: true,
    },
  })
  .to('.headline', {
    '--emWidth': '100%',
    ease: Linear.easeNone,
   });
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.general-content {
  padding: 150px 0;
  background-image: url('@/assets/img/bg-content.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;

  @include mobile {
    padding: 120px 0 110px;
  }

  &.has-animation {
    margin: 0 0 200px;

    @include mobile {
      margin: 0;
    }
  }

  .general-content-container {
    text-align: center;

    &.has-animation {
      padding: 0 0 150px;

      @include mobile {
        padding: 0;
      }
    }

    .headline {
      --emWidth: 0%;

      em {
        
        &:after {
          width: var(--emWidth);
        }
      }
    }

    .text {
      max-width: 900px;
      margin: 75px auto 0;
    }
  }

  .gc-anchors {
    display: flex;
    max-width: 900px;
    padding: 0 40px;
    margin: 100px auto 0;
    border-top: 1px solid rgba(0,0,0,0.1);

    @include mobile {
      flex-direction: column;
      padding: 0;
      border: none;
    }
    
    .anchor {
      position: relative;
      flex: 1;
      counter-increment: listStyle;

      &:first-child {

        a:before {

          @include mobile {
            height: calc(100% + 30px);
          }
        }
      }

      @include mobile {
        padding: 0 30px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      a {
        position: relative;
        display: block;
        padding: 25px 25px 25px 2px;

        @include mobile {
          padding-left: 20px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 1px;
          height: calc(100% + 30px);
          background-color: rgba(0, 0, 0, 0.1);

          @include mobile {
            height: 100%;
          }
        }

        &:after {
          content: counter(listStyle, upper-alpha);
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          transform: translate(-50%, -50%);
          font-size: 1.12rem;
          line-height: 1;
          border-radius: 50%;
          background: linear-gradient(90deg, #FFA308 -13.76%, #FF5F06 45.07%, #DF1A1A 99.99%);
          color: $white;

          @include mobile {
            width: 30px;
            height: 30px;
            font-size: 1.32rem;
          }
        }

        p {
          font-size: 1.4rem;

          @include mobile {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  .spline {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 512px;
    height: 512px;
    margin: auto;
    transform: translateY(50%);

    @include mobile {
      display: none;
    }
  }
}
</style>
