<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <div
        ref="animation"
        class="intro-animation"></div>
    </div>
  </div>
</template>

<script setup>
import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/intro.json'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const { setIntroComplete } = useIntroShown()
const { locoScroll } = useSmoothScroll()
const animation = ref()

onMounted(() => {
  locoScroll.value.stop()
  document.documentElement.classList.add('no-overflow')

  const anim = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: true,
    loop: false,
    animationData: introJson,
  })

  anim.onComplete = () => {
    setTimeout(() => {
      locoScroll.value.start()
      document.documentElement.classList.remove('no-overflow')
      setIntroComplete()
    }, 1000)
  }
})

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss">
.intro {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 12;
  background-color: $white;

  .animation-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .intro-container {
    position: relative;
    width: 500px;
    height: 500px;
    z-index: 13;
  }
}
</style>
