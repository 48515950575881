<template>
  <div class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-if="page.template === 'page-legal.php'">
          <div class="page-legal">
            <div class="container small editor-formatting">
              <h1>{{ page.title.rendered }}</h1>
              <div class="legal-content" v-html="page.content.rendered"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="(module, index) in page.acf.modules">
            <template v-if="module.acf_fc_layout === 'HomeHeroComponent'">
              <HomeHeroComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'HeroComponent'">
              <HeroComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'SciencePageCalloutComponent'">
              <SciencePageCalloutComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'AnimationPageCalloutComponent'">
              <AnimationPageCalloutComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'CareersPageCalloutComponent'">
              <CareersPageCalloutComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ActivationLethalityComponent'">
              <ActivationLethalityComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'SliderComponent'">
              <SliderComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'GeneralContentComponent'">
              <GeneralContentComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'NewsComponent'">
              <NewsComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TeamComponent'">
              <TeamComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'InvestorsComponent'">
              <InvestorsComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ActivationLethalitySliderComponent'">
              <ActivationLethalitySliderComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'AnchorComponent'">
              <AnchorComponent :key="index" :data="module" :index="index" />
            </template>
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

import HomeHeroComponent from '@/components/modules/HomeHeroComponent.vue'
import HeroComponent from '@/components/modules/HeroComponent.vue'
import SciencePageCalloutComponent from '@/components/modules/SciencePageCalloutComponent.vue'
import AnimationPageCalloutComponent from '@/components/modules/AnimationPageCalloutComponent.vue'
import CareersPageCalloutComponent from '@/components/modules/CareersPageCalloutComponent.vue'
import ActivationLethalityComponent from '@/components/modules/ActivationLethalityComponent.vue'
import SliderComponent from '@/components/modules/SliderComponent.vue'
import GeneralContentComponent from '@/components/modules/GeneralContentComponent.vue'
import NewsComponent from '@/components/modules/NewsComponent.vue'
import TeamComponent from '@/components/modules/Team/TeamComponent.vue'
import InvestorsComponent from '@/components/modules/InvestorsComponent.vue'
import ActivationLethalitySliderComponent from '@/components/modules/ActivationLethalitySliderComponent.vue'
import AnchorComponent from '@/components/modules/AnchorComponent.vue'

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const navState = inject('navState')

const page = computed(() => {
  return store.getCurrentSingleBySlug(request)
})

onBeforeMount(() => getPage())

onMounted(() => {
  const anchor = router.currentRoute.hash
  nextTick(() => {
    if (anchor && document.querySelector(anchor)) {
      location.href = anchor
    }
  })
})

const getPage = async () => {
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        navState.value.class = ''
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}
</script>

<style lang="scss">
.page-legal {
  padding: 150px 0;

  @include mobile {
    padding: 60px 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url('@/assets/img/bg-gradient-team.png');
    background-repeat: no-repeat;
  }
}
</style>
