<template>
  <article class="post-item">
    <a
      :href="post.acf.post_external ? post.acf.post_external : props.post.link"
      :title="props.post.post_title"
      :target="post.acf.post_external ? '_blank' : ''">
      <div class="post-info text-smaller">
        <time>{{ formatDate(props.post.date) }}</time>
        <PostTaxonomies :post="props.post" />
      </div>
      <p class="title h4" v-html="props.post.title.rendered"></p>
      <p class="btn orange">View</p>
    </a>
  </article>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import PostTaxonomies from '@/components/utility/PostTaxonomies.vue'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})

const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: "numeric",
    month: "short",
  }

  return new Intl.DateTimeFormat("en-US", dateOptions).format(date)
}

onMounted(() => {
  // const date = new Date(props.post.date)
  // let options = {
  //   year: "numeric",
  //   month: "short",
  // }
  // console.log(new Intl.DateTimeFormat("en-DE", options).format(date));
})
</script>

<style lang="scss">
.post-item {
  width: 50%;
  padding: 58px 50px 40px 40px;
  border: 1px solid rgba(0, 0, 0, 0.20);

  @include mobile {
    width: 100%;
    padding: 50px 25px;
    border-left: none;
    border-right: none;
  }

  &:nth-child(even) {
    border-left: none;
  }

  &:nth-child(n+3) {
    border-top: none;
  }

  &:first-child {
    border-top-left-radius: 5px;
  }

  &:nth-child(2) {
    border-top-right-radius: 5px;
  }

  &:nth-last-child(2):nth-child(odd) {
    border-bottom-left-radius: 5px;
  }

  &:nth-last-child(2):nth-child(even) {
    border-bottom-right-radius: 5px;
  }

  &:last-child:nth-child(odd) {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child:nth-child(even) {
    border-bottom-right-radius: 5px;
  }

  &:only-child {
    border-radius: 5px;
  }

  .post-info {
    display: flex;
    margin: 0 0 60px;

    @include mobile {
      margin: 0 0 40px;
    }

    .post-meta {
      display: flex;
      align-items: center;
      color: $orange;

      &:before {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        margin: 0 25px;
        background-color: $black;
      }

      .post-category {
        margin-right: 10px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
  }

  .btn {
    margin: 60px 0 0 auto;

    @include mobile {
      margin: 40px auto 0 0 ;
    }
  }
}
</style>
