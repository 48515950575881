<template>
  <div class="team-section">
    <template v-if="team.length > 0">
      <div
        v-for="member in team"
        :key="member.id"
        class="team-member">
        <a
          :href="
            member.acf.team_member_external_link
              ? member.acf.team_member_external_link
              : member.link
          "
          :target="member.acf.team_member_external_link ? '_blank' : ''">
          <div class="image-container">
            <ResponsiveImage
              v-if="member.featured_media"
              :media-id="member.featured_media" />
            <div
              v-else
              class="image-placeholder"></div>
          </div>
          <div class="name-title">
            <div
              class="name text-larger"
              v-html="member.title.rendered"></div>
            <div
              class="title text-smaller"
              v-html="member.acf.team_member_title"></div>
          </div>
        </a>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in data.team_list"
        :key="index"
        class="team-placeholder">
        <div class="placeholder-inner"></div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { ref, onMounted, computed } from 'vue'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const store = useStore()
const props = defineProps(['data'])
const loading = ref(true)

const request = {
  type: 'team',
  params: {
    include: props.data.team_list,
    orderby: 'include',
    _fields: 'id,slug,link,title,featured_media,excerpt,acf,content',
    per_page: 100,
  },
  showLoading: false,
}

const team = computed(() => {
  return store.requestedItems(request)
})

const getTeam = () => {
  store.getItems(request).then(() => {
    loading.value = false
  })
}

onMounted(() => {
  getTeam()
})
</script>

<style lang="scss">
.team-section {
  display: flex;
  flex-wrap: wrap;

  .team-member,
  .team-placeholder {
    width: calc(25% - 30px);
    margin-right: 40px;
    margin-bottom: 40px;

    @include mobile {
      width: 100%;
      margin: 0 0 30px;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  .team-member {

    a {

      @include mobile {
        display: flex;
        align-items: center;
      }
    }

    .image-container,
    .image-placeholder {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 118%;
      margin: 0 0 24px;

      @include mobile {
        width: 25%;
        height: auto;
        padding: 0;
        margin: 0 7% 0 0;
      }

      .repsonsive-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .name-title {

      @include mobile {
        flex: 1;
      }

      .name {
        margin: 0 0 5px;
      }
    }
  }

  .team-placeholder {
    
    .placeholder-inner {
      width: 100%;
      padding-bottom: 118%;
      margin: 0 0 45px;
    }
  }
}
</style>
