<template>
  <header
    id="header-nav"
    ref="header"
    class="header-nav"
    :class="`${navOpen ? 'nav-open' : ''} ${direction} ${
      scrolling ? 'scrolling' : ''
    }`">
    <nav class="nav-container">
      <div class="nav-outer-container">
        <div class="logo" @click="close">
          <a href="/">
            <img
              :src="site.nav_logo.url"
              alt="Delphiatx logo"
            />
          </a>
        </div>
        <div class="nav-wrapper" :class="`${navOpen ? 'nav-open' : ''}`">
          <div class="mobile-lines">
            <div class="line down"></div>
            <div class="line across"></div>
            <span></span>
          </div>
          <ul ref="nav">
            <template v-for="item in menu" :key="item.id">
              <li v-if="item.parent === '0'">
                <template v-if="isExternal(item.url)">
                  <a
                    @click="close"
                    :href="item.url"
                    :target="item.target"
                    class="no-router"
                    :tabindex="active ? 0 : -1"
                    v-html="item.content"
                  ></a>
                </template>
                <template v-else>
                  <router-link
                    @click="close"
                    :to="convertToRelative(item.url)"
                    exact
                    v-html="item.content">
                  </router-link>
                </template>
              </li>
            </template>
          </ul>
        </div>
        <div class="hamburger" @click="open" :class="`${navOpen ? 'nav-open' : ''}`">
          <div class="hamlines">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <p>{{navOpen ? 'Close' : "Menu"}}</p>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from '@/stores/main'
import { gsap } from 'gsap'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import useUtils from '@/components/composables/useUtils.js'

const { convertToRelative, isExternal } = useUtils()

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref(null)
const nav = ref(null)
const navOpen = ref(false)
const direction = ref('down')
const scrollTop = ref(0)
const scrolling = ref(false)
const { introShown } = useIntroShown()

const { locoScroll } = useSmoothScroll()

let tl

const open = () => {
  if (navOpen.value) {
    navOpen.value = false
    locoScroll.value.start()
  } else {
    navOpen.value = true
    locoScroll.value.stop()
  }
}

const close = () => {
  navOpen.value = false
  locoScroll.value.start()
}

function handleScroll() {

  if (window.scrollY >= scrollTop.value) {
    scrollTop.value = window.scrollY
    direction.value = 'up'
  } else {
    scrollTop.value = window.scrollY
    direction.value = 'down'
  }

  if (scrollTop.value >= 100) {
    scrolling.value = true
  } else {
    direction.value = 'down'
    scrolling.value = false
  }
}

const hasIntroShown = () => {
  if (introShown.value) {
    tl.play()
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 250)
  }
}

onMounted(() => {

  tl = gsap.timeline({
    paused: true
  })
  .to(nav.value, {
    y: '0%',
    duration: 1.5
  })

  hasIntroShown();
  document.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  tl.kill()
  document.removeEventListener('scroll', handleScroll, false)
})
</script>

<style lang="scss">
.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translateY(0%);
  @include transition;

  &.up {
    transform: translateY(-100%);
  }

  &.scrolling {

    .nav-container {

      .nav-outer-container {
        background: rgba(199, 255, 255, 0.80);
        backdrop-filter: blur(15px);

        .nav-wrapper:after {
          opacity: 1;
        }
      }
    }
  }

  .nav-container {
    display: flex;
    margin: auto;
    justify-content: center;

    .nav-outer-container {
      display: flex;
      align-items: center;
      background: transparent;
      backdrop-filter: blur(0px);
      @include transition;

      @include mobile {
        width: 100%;
      }

      .logo {
        width: 173px;
        padding: 14px 15px;
        border-right: 1px solid rgba(62, 62, 62, 0.33);

        @include mobile {
          flex: 1;
          z-index: 1;
        }

        a {
          display: block;

          img {
            width: 100%;

            @include mobile {
              max-width: 165px;
              margin: auto;
            }
          }
        }
      }

      .nav-wrapper {
        position: relative;

        @include mobile {
          visibility: hidden;
          pointer-events: none;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          padding: 80px 0 40px;
          background-color: $baby-blue;
          @include transition;

          &.nav-open {
            visibility: visible;
            pointer-events: all;
            opacity: 1;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 5px;
          background: linear-gradient(270deg, #DF1A1A 0%, #FF5F06 31.5%, #FFA308 68.5%, #FFF 100%);
          opacity: 0;
          @include transition;
        }

        .mobile-lines {
          position: relative;
          display: none;

          @include mobile {
            display: block;
          }

          .line {
            background-color: #AADEDE;

            &.across {
              position: absolute;
              top: 50%;
              width: 100%;
              height: 1px;
              transform: translateY(-50%);
            }

            &.down {
              position: relative;
              width: 1px;
              height: 100px;
              left: 45px;
            }
          }

          span {
            position: absolute;
            top: 50%;
            left: 42px;
            width: 7px;
            height: 7px;
            transform: translateY(-50%);
            border: 1px solid $black;
            border-radius: 50%;
          }
        }

        ul {
          position: relative;
          display: flex;
          transform: translateY(-100%);

          @include mobile {
            transform: none !important;
            flex-direction: column;
            width: calc(100% - 50px);
            margin-left: auto;
          }

          li {

            a {
              position: relative;
              display: block;
              line-height: 1;
              padding: 30px;

              &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 5px;
                transform: rotate(180deg) scaleX(-1);
                background: linear-gradient(270deg, #DF1A1A 0%, #FF5F06 35.5%, #FFA308 68.5%, #FFF 100%);
                @include transition;
              }

              &.router-link-active {

                &:after {
                  width: 100%;
                }
              }

              @include mobile {
                font-size: 4rem;
                padding: 20px;
                @include transition;

                &:after {
                  display: none;
                }

                &:not(.router-link-exact-active) {
                  @include font-main-light;
                }
              }
            }
          }
        }
      }

      .hamburger {
        display: none;
        width: 150px;
        height: 80px;
        padding: 0 40px;
        z-index: 1;

        &.nav-open {

          .hamlines {

            span {

              &:first-child {
                height: 0px;
                margin: 0;
              }

              &:nth-child(2) {
                margin: 0;
              }

              &:last-child {
                height: 0px;
              }
            }
          }

          p {
            font-size: 1rem;
            letter-spacing: 3.3px;
          }
        }

        @include mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .hamlines {
          position: relative;
          margin: 0 0 5px;

          span {
            display: block;
            width: 48px;
            background-color: $black;
            @include transition;

            &:first-child {
              height: 2px;
              margin: 0 0 6px;
            }

            &:nth-child(2) {
              height: 5px;
              margin: 0 0 5px;
            }

            &:last-child {
              height: 9px;
            }
          }
        }

        p {
          font-size: 1.2rem;
          text-transform: uppercase;
          letter-spacing: 3.96px;
          @include font-main-bold;
          @include transition;
        }
      }
    }
  }
}

.v-home {
  .header-nav .nav-container .nav-outer-container .nav-wrapper ul li a {
    @include font-main;
  }
}
</style>
