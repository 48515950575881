<template>
  <footer v-show="!store.site.loading && site"  class="site-footer">
    <div class="footer-container">
      <div class="footer-head">
        <div class="footer-logo">
          <router-link
            :to="'/'"
            title="Home">
            <img :src="site.footer_logo.url" alt="Delphiatx Logomark" class="logo" />
          </router-link>
          <div class="socials mobile">
            <a
              v-if="site.social_twitter"
              :href="site.social_twitter"
              target="_blank"
              class="social twitter">
              <img
                src="@/assets/img/logo-x.svg"
                alt="X Logo" />
            </a>
            <a
              v-if="site.social_linkedin"
              :href="site.social_linkedin"
              target="_blank"
              class="social linkedin">
              <img
                src="@/assets/img/logo-linkedin.svg"
                alt="LinkedIn Logo" />
            </a>
          </div>
        </div>
        <div class="footer-nav-contact">
          <div class="footer-nav">
            <FooterMenu name="footer-menu" weight="h3" />
          </div>
          <div class="footer-contact">
            <div class="address" v-if="site.footer_address" v-html="site.footer_address">
            </div>
            <div class="numbers" v-if="site.footer_emails" v-html="site.footer_emails"></div>
          </div>
        </div>
      </div>
      <div class="footer-footer">
        <div class="legal-copyright">
          <div class="copyright">
            <p>Copyright &copy; {{ currentYear }} Delphia Therapeutics</p>
          </div>
          <div class="legal-menu">
            <FooterMenu name="legal-menu" />
          </div>
        </div>
        <div class="socials">
          <a
            v-if="site.social_twitter"
            :href="site.social_twitter"
            target="_blank"
            class="social twitter">
            <img
              src="@/assets/img/logo-x.svg"
              alt="X Logo" />
          </a>
          <a
            v-if="site.social_linkedin"
            :href="site.social_linkedin"
            target="_blank"
            class="social linkedin">
            <img
              src="@/assets/img/logo-linkedin.svg"
              alt="LinkedIn Logo" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const site = ref(store.acfOptions())

const scrollPos = ref(0)

const refreshScrollPositions = () => {
  if (scrollPos.value != window.pageYOffset) {
    scrollPos.value = window.pageYOffset
    ScrollTrigger.refresh()
  }
}

const currentYear = computed(() => {
  return new Date().getFullYear()
})

onMounted(() => {
  setInterval(refreshScrollPositions, 3000)
})

onBeforeUnmount(() => {
  clearInterval(refreshScrollPositions)
})
</script>

<style lang="scss">
.site-footer {
  position: relative;
  padding: 100px 0 25px;
  z-index: 1;
  background-color: $baby-blue;

  @include mobile {
    padding: 40px 0;
  }

  .footer-container {
    width: 1400px;
    max-width: 90%;
    margin: auto;

    .footer-head {
      display: flex;
      justify-content: space-between;
      width: 91%;
      margin: 0 auto 70px;

      @include mobile {
        flex-direction: column;
        width: 100%;
        margin: 0 0 40px;
      }

      .footer-logo {

        @include mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 40px;
        }

        a {
          display: block;
          
          img.logo {
            width: 150px;

            @include mobile {
              width: 100px;
            }
          }
        }
      }

      .footer-nav-contact {
        display: flex;
        
        @include mobile {
          flex-direction: column;
        }

        .footer-nav {
          margin-right: 100px;

          @include mobile {
            margin: 0 0 40px;
          }

          li {
            
            @include mobile {
              margin: 0 0 10px;

              &:last-child {
                margin: 0;
              }
            }
          }
        }

        .footer-contact {

          .address {
            margin: 0 0 80px;

            @include mobile {
              margin: 0 0 40px;
            }
          }
        }
      }
    }
    
    .footer-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      .legal-copyright {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        @include font-main-light;

        @include mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        .copyright {
          display: flex;
          align-items: center;

          @include mobile {
            order: 2;
          }
          
          &:after {
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            margin: 0 20px;
            border-radius: 50%;
            transform: rotate(45deg);
            background-color: $off-black;

            @include mobile {
              display: none;
            }
          }
        }

        .legal-menu {
          
          @include mobile {
            margin: 0 0 40px;
          }

          li {
            display: flex;
            align-items: center;

            @include mobile {
              margin: 0 0 10px;

              &:last-child {
                margin: 0;
              }
            }

            &:last-child:after {
              display: none;
            }

            &:after {
              content: '';
              display: block;
              width: 3px;
              height: 3px;
              margin: 0 20px;
              border-radius: 50%;
              transform: rotate(45deg);
              background-color: $off-black;

              @include mobile {
                display: none;
              }
            }
          }
        }
      }
    }

    .socials {
      display: flex;

      @include mobile {
        display: none;
      }

      &.mobile {
        display: none;

        @include mobile {
          display: flex;
        }
      }

      a {
        margin-right: 12px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>
