<template>
  <div class="wrapper">
    <main class="single-post">
      <div class="container med">
        <div class="btn-container">
          <a href="/news" class="btn-gradient">Back to News</a>
        </div>
        <template v-if="post">
          <article>
            <div class="lines left">
              <span></span>
            </div>
            <div class="post-title">
              <h1 class="title h3" v-html="post.title.rendered"></h1>
            </div>
            <div v-if="post.featured_image" class="featured-image mt-10">
              <img :src="post.featured_image.large" :alt="post.featured_image.alt" />
            </div>
            <div class="post-content">
              <template v-if="post.acf.post_external">
                <a :href="post.acf.post_external">Read More</a>
              </template>
              <template v-else>
                <div class="editor-formatting" v-html="post.content.rendered"></div>
              </template>
            </div>
          </article>
        </template>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '@/stores/main'
// import { format } from 'date-fns'
import useUtils from '@/components/composables/useUtils.js'

const { decodeTitle } = useUtils()

const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
})

const request = {
  type: 'posts',
  slug: props.slug,
  showLoading: true,
}

const date = ref('')
const post = computed(() => {
  return store.singleBySlug(request)
})

const getPost = async () => {
  store.getSingleBySlug(request)
  if (post.value) {
    store.updateDocTitle({
      parts: [decodeTitle(post.value.title.rendered), store.site.name],
    })

    // date.value = format(new Date(post.value.date), 'MM/d/yyyy')
  }
}

onBeforeMount(() => getPost())
</script>

<style lang="scss">
.single-post {
  position: relative;
  padding: 150px 0 120px;
  overflow: hidden;

  @include mobile {
    padding: 100px 0 40px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url('@/assets/img/bg-gradient-team.png');
    background-repeat: no-repeat;
  }

  .btn-container {
    display: flex;
    margin: 0 0 30px;

    @include mobile {
      justify-content: center;
    }
  }

  article {
    position: relative;
    max-width: 864px;
    margin: auto;

    .lines {
      position: absolute;
      top: -70px;
      left: -55px;
      z-index: 0;
  
      @include mobile {
        top: -65px;
        left: -6%;
      }
  
      &.left {
  
        &:before {
          left: 30px;
        }
  
        &:after {
          left: 0;
        }
  
        span {
          left: 27px;
        }
      }
  
      &:before,
      &:after,
      span {
        position: absolute;
        top: 0;
        display: block;
      }
  
      &:before,
      &:after {
        content: '';
        background: rgba(0, 0, 0, 0.20);
      }
  
      &:before {
        width: 1px;
        height: 120px;
      }
  
      &:after {
        top: 50px;
        width: 373px;
        height: 1px;
      }
  
      span {
        top: 47px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        z-index: 1;
        border: 1px solid $black;
      }
    }
  }

  .title {

  }
}
</style>
