<template>
  <section ref="heroTrigger" class="home-hero">
    <div ref="bg" class="hero-bg">
      <SplineViewerComponent v-if="showAnimation" :scene="dunes" />
    </div>
    <div ref="linesTrigger" class="hero-lines-container">
      <div ref="wrapper" class="hero-lines-wrapper">
        <div class="hero-lines" ref="headline">
          <div class="hero-line-container" v-for="(line, index) in data.home_hero_lines" :key="index">
            <div class="hero-line h1">
              <div class="start" :class="{'no-end': !line.home_hero_line_end}">{{ line.home_hero_line_start }}</div>
              <div class="end" v-if="line.home_hero_line_end">{{ line.home_hero_line_end }}</div>
            </div>
          </div>
        </div>
        <div ref="iconScroll" class="icon-scroll">
          <img src="@/assets/img/icon-scroll-down.svg" alt="">
        </div>
      </div>
    </div>
    <div ref="underlinesTrigger" class="hero-footer h3" v-html="data.home_hero_footer"></div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HomeHeroComponent",
    "CLASSNAME": "home-hero",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import SplineViewerComponent from '@/components/utility/SplineViewerComponent.vue'
import { ref, onBeforeUnmount, onMounted } from 'vue'
import useIntroShown from '@/components/composables/useIntroShown.js'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import dunes from '@/assets/spline/dunes2.splinecode?url'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const heroTrigger = ref(null)
const bg = ref(null)
const linesTrigger = ref(null)
const headline = ref(null)
const wrapper = ref(null)
const iconScroll = ref(null)
const showAnimation = ref(false)
const { introShown } = useIntroShown()

let tlLines
let tlBg

const tlHeadline = gsap.timeline({
  paused: true,
  delay: 0.5,
})

const hasIntroShown = () => {
  if (introShown.value) {
    tlHeadline.play()
    showAnimation.value = true
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 100)
  }
}

const headlineTween = () => {
  tlHeadline.staggerTo(headline.value.children, 0.75, {
    clipPath: 'polygon(0 0, 100% 0, 100% 110%, 0 110%)',
    y: 0,
    delay: 0.15,
  }, 0.15)
}

onMounted(() => {

  tlBg = gsap.timeline({
    scrollTrigger: {
      trigger: heroTrigger.value,
      pin: bg.value,
      pinSpacing: true,
      start: 'top top',
      end: 'bottom bottom-=100',
    },
  })

  tlLines = gsap.timeline({
    scrollTrigger: {
      trigger: linesTrigger.value,
      pin: wrapper.value,
      pinSpacing: true,
      start: 'top top',
      end: 'bottom bottom',
      scrub: true
    },
  })
  .to('.start:not(.no-end)', {
    y: '-100%',
    ease: Linear.easeNone,
  })
  .to('.end', {
    y: '0%',
    ease: Linear.easeNone,
  }, 0)
  .to(iconScroll.value, {
    bottom: 150,
    ease: Linear.easeNone,
  }, 0)

  headlineTween()
  hasIntroShown()
})

onBeforeUnmount(() => {
  tlBg.kill()
  tlLines.kill()
  tlHeadline.kill()
  showAnimation.value = false
})
</script>

<style lang="scss">
.home-hero {
  padding: 0 0 100px;

  .hero-bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -1;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .hero-lines-container {
    position: relative;
    height: 200vh;
    text-align: center;
    pointer-events: none;

    @include mobile {
      pointer-events: all;
    }

    .hero-lines-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;

      .hero-lines {
        position: relative;
        width: 1074px;
        max-width: 90%;
        margin: auto;

        >* {
          clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
          transform: translateY(100%);
        }

        .hero-line-container {
          position: relative;

        }

        .hero-line {
          overflow: hidden;
          position: relative;
          text-transform: uppercase;

          @include mobile {
            font-size: 10vw;
          }

          .start {
            transform: translateY(0%);

            &.no-end {
              transform: translateY(0%) !important;
            }
          }

          .end {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(100%);
          }
        }
      }

      .icon-scroll {
        position: absolute;
        bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .hero-footer {
    width: 90%;
    max-width: 900px;
    margin: auto;
    text-align: center;
  }
}
</style>
