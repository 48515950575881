<template>
  <section class="team-module module" id="team">
    <div class="lines right">
      <span></span>
    </div>
    <div class="container">
      <div class="team-intro">
        <div class="headline-subhead">
          <div class="headline h3" v-html="data.team_headline"></div>
          <div class="subhead h4 orange-em" v-html="data.team_subhead"></div>
        </div>
        <div class="text editor-formatting" v-html="data.team_text"></div>
      </div>
      <div class="filter team-filter">
        <div class="filter-wrapper">
          <div
            v-for="(cat, index) in data.team_category"
            :key="index"
            class="category"
            :class="{ active: index == activeTeam }"
            @click="updateFilter(index)">
            <p>{{ cat.team_category_name }}</p>
          </div>
        </div>
      </div>
      <div
        class="filter-dropdown"
        :class="{ open: isOpen }"
        data-lenis-prevent>
        <div
          class="filter-btn"
          :class="{ open: isOpen }"
          @click="open">
          <div class="name">
            {{ activeFilter }}
          </div>
          <img
            class="arrow"
            src="@/assets/img/dropdown-arrow.svg"
            alt="Drawer Arrow" 
          />
        </div>
        <Vue3SlideUpDown v-model="isOpen">
          <div class="filter-wrapper">
            <div
              v-for="(cat, index) in data.team_category"
              :key="index"
              class="category"
              :class="{ active: index == activeTeam }"
              @click="updateFilter(index, cat.team_category_name)">
              <p>{{ cat.team_category_name }}</p>
            </div>
          </div>
        </Vue3SlideUpDown>
      </div>
    </div>
    <div class="container large">
      <div class="teams-container">
        <template
          v-for="(category, index) in data.team_category"
          :key="index">
          <Team
            v-if="index == activeTeam"
            :data="category" />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TeamComponent",
    "CLASSNAME": "team-module",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import Team from '@/components/modules/Team/Team.vue'
import { ref } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

defineProps(['data'])

const activeTeam = ref(0)
const activeFilter = ref('Leadership')
const isOpen = ref(false)

const updateFilter = (idx, name) => {
  activeTeam.value = idx
  activeFilter.value = name
  isOpen.value = false
}

const open = () => {
  isOpen.value = !isOpen.value
}
</script>

<style lang="scss">
.team-module {
  overflow: hidden;

  .lines {
    position: absolute;
    top: 0;
    z-index: 0;

    @include mobile {
      top: -20px;
    }

    &.right {
      left: 50%;

      @include mobile {
        left: inherit;
        right: 0;
      }

      &:before {
        right: 30px;
      }

      &:after {
        right: 0;
      }

      span {
        right: 27px;
      }
    }

    &:before,
    &:after,
    span {
      position: absolute;
      top: 0;
      display: block;
    }

    &:before,
    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.20);
    }

    &:before {
      width: 1px;
      height: 120px;
    }

    &:after {
      top: 50px;
      width: 373px;
      height: 1px;
    }

    span {
      top: 47px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      z-index: 1;
      border: 1px solid $black;
    }
  }

  .team-intro {
    display: flex;
    justify-content: space-between;
    margin: 0 0 150px;

    @include mobile {
      flex-direction: column;
      margin: 0 0 80px;
    }

    .headline-subhead,
    .text {
      flex: 1;
    }

    .headline-subhead {
      margin-right: 40px;

      @include mobile {
        margin: 0 0 40px;
      }

      .subhead {
        margin: 20px 0 0;
      }
    }
  }

  .team-filter {
    margin: 0 0 60px;
  }

  .filter-dropdown {
    margin: 0 0 30px;
  }
}
</style>
