<template>
  <section class="slider">
    <div class="container large">
      <div class="page-indicator" v-if="data.slider_indicator_toggle">
        <div class="indicator-letter">{{ data.slider_indicator_letter }}</div>
        <div class="indicator-content">
          <div class="indicator-subtitle text-smaller">
            {{ data.slider_indicator_title}}
          </div>
        </div>
      </div>
      <div class="slider-container">
        <div class="slider-content">
          <div class="content-wrapper">
            <div class="headline h3" v-html="data.slider_headline"></div>
            <div class="text editor-formatting" v-html="data.slider_text"></div>
          </div>
        </div>
        <div class="slides">
          <Splide :has-track="false" :options="options" @splide:move="getCurrSlide">
            <SplideTrack>
              <SplideSlide v-for="(slide, index) in data.slider_slides" :key="index">
                <img v-if="slide.slide_image" :src="slide.slide_image.url" :alt="slide.slide_image.alt" />
                <div class="slide-content">
                  <div class="title h4" v-html="slide.slide_title"></div>
                  <div class="text editor-formatting" v-html="slide.slide_text"></div>
                </div>
              </SplideSlide>
            </SplideTrack>

            <div class="splide__arrows">
              <button class="splide__arrow splide__arrow--prev"></button>
              <div class="count-wrapper">
                <div class="curr">
                  <div class="curr-container"
                    :style="`transform: translateY(-${currSlide * 100 / data.slider_slides.length}%)`">
                    <p v-for="(slide, index) in data.slider_slides">{{ index + 1 }}</p>
                  </div>
                </div>
                <div class="slide-length">
                  <p>/{{ data.slider_slides.length }}</p>
                </div>
              </div>
              <button class="splide__arrow splide__arrow--next"></button>
            </div>
          </Splide>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "SliderComponent",
    "CLASSNAME": "slider",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css/core'

defineProps(['data'])

const options = {
  pagination: false,
  arrows: true,
  autoplay: false,
  speed: 600,
  // type: 'loop',
}

const currSlide = ref(0);

const getCurrSlide = (splide, prev, next) => {
  currSlide.value = prev
}
</script>

<style lang="scss">
.slider {
  padding: 100px 0;

  @include mobile {
    padding: 40px 0;
  }

  .page-indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    @include tablet-landscape(1) {
      position: relative;
      top: 0;
      left: 0;
      align-self: flex-start;
      margin: 0 0 40px;
    }

    .indicator-letter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      margin-right: 17px;
      border-radius: 50%;
      background: linear-gradient(90deg, #FFA308 -13.76%, #FF5F06 45.07%, #DF1A1A 99.99%);
      font-size: 2.8rem;
      color: $white;

      @include mobile {
        width: 50px;
        height: 50px;
        font-size: 2rem;
      }
    }
  }

  .slider-container {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .slider-content,
    .slides {
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }

    .slider-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        margin: 0 0 40px;
      }

      .content-wrapper {
        width: 85%;
        max-width: 540px;
      }

      .headline {
        margin: 0 0 40px;
      }
    }

    .slides {
      position: relative;
      background-color: #F7F7F7;

      .splide__slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 80px 60px;

        @include mobile {
          padding: 30px 20px 60px;
        }

        img {
          max-height: 70%;
          margin: auto;
        }

        .slide-content {

          .title {
            margin: 0 0 20px;
          }
        }
      }

      .splide__arrows {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
        padding: 17px;
        border-radius: 54px;
        background: linear-gradient(90deg, #FFA308 0%, #FFA308 11.33%, #FF5F06 31.28%, #DF1A1A 50.99%, #FF5F06 69.95%, #FFA308 90.89%, #FFA308 100%);
        z-index: 1;

        @include mobile {
          left: initial;
          right: 0;
          transform: translateY(50%);
        }

        &--prev {
          transform: rotate(180deg);
        }

        button {
          cursor: pointer;
          width: 45px;
          height: 45px;
          padding: 0;
          opacity: 1;
          background-image: url('@/assets/img/icon-slider-arrow.svg');
          background-repeat: no-repeat;
          background-size: contain !important;
          background-color: transparent !important;
          border: none !important;
          @include transition;

          &:disabled {
            cursor: default;
            opacity: 0.7;
          }

          &.splide__arrow--prev {
            transform: rotate(180deg);
          }

          svg {
            display: none;
          }
        }

        .count-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 76px;
          padding: 0 10px;

          .curr,
          .slide-length {
            font-size: 3rem;
            color: $white;
            @include font-alt;
          }
        }

        .curr {
          height: 42px;
          text-align: center;
          overflow: hidden;

          .curr-container {
            @include transition;
          }
        }
      }
    }
  }
}
</style>
