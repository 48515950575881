<template>
  <section class="activation-lethality-slider">
    <div class="container large">
      <div class="lines-container">
        <div class="lines left">
          <span></span>
        </div>
        <div class="lines right">
          <span></span>
        </div>
      </div>
      <div class="slider-container">
        <div class="page-indicator" v-if="data.activation_lethality_slider_indicator_toggle">
          <div class="indicator-letter">{{ data.activation_lethality_slider_indicator_letter }}</div>
          <div class="indicator-content">
            <div class="indicator-subtitle text-smaller">
              {{ data.activation_lethality_slider_indicator_title}}
            </div>
          </div>
        </div>
        <Splide :has-track="false" :options="options" @splide:move="getCurrSlide" @splide:mounted="getSliderInstance">
          <SplideTrack>
            <SplideSlide v-for="(item, index) in data.activation_lethality_slider" :key="index">
              <div class="slide">
                <div class="image-container">
                  <img :src="item.activation_lethality_slider_image.url"
                    :data-splide-lazy="item.activation_lethality_slider_image.url"
                    :alt="item.activation_lethality_slider_image.alt">
                </div>
                <div class="slide-content-container">
                  <div class="indicator-title"
                    v-if="data.activation_lethality_slider_indicator_title && !item.activation_lethality_slider_text">
                    {{ data.activation_lethality_slider_indicator_title }}
                  </div>
                  <div class="desc h4" :class="{ 'h5': item.activation_lethality_slider_text }"
                    v-html="item.activation_lethality_slider_desc"></div>
                  <div class="text editor-formatting" v-html="item.activation_lethality_slider_text"
                    v-if="item.activation_lethality_slider_text"></div>
                </div>
              </div>
            </SplideSlide>
          </SplideTrack>
          <div class="splide__arrows">
            <div class="numbers">
              <template v-for="(item, index) in data.activation_lethality_slider" :key="index">
                <template v-if="item.activation_lethality_slider_label">
                  <div class="num label" @click="goTo(index)" :class="{ 'active': index == currSlide }">
                    <div class="label-content" v-html="item.activation_lethality_slider_label"></div>
                  </div>
                </template>
                <template v-else>
                  <div class="num" @click="goTo(index)" :class="{ 'active': index == currSlide }">
                    <p>{{ index + 1 }}
                    </p>
                  </div>
                </template>
              </template>
            </div>
            <button class="splide__arrow splide__arrow--prev">
              <img src="@/assets/img/caret-arrow.svg" alt="icon arrow">
            </button>
            <button class="splide__arrow splide__arrow--next">
              <img src="@/assets/img/caret-arrow.svg" alt="icon arrow">
            </button>
          </div>

          <div class="slide-progress-container">
            <div class="slide-progress"
              :style="`width: ${(currSlide / (data.activation_lethality_slider.length - 1)) * 100}%`">
            </div>
          </div>
        </Splide>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "ActivationLethalitySliderComponent",
    "CLASSNAME": "activation-lethality-slider",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css/core'

defineProps(['data'])

const options = {
  pagination: false,
  arrows: true,
  autoplay: false,
  speed: 1000,
  type: 'fade',
}

const currSlide = ref(0);
const splideInstance = ref()

const getCurrSlide = (splide, prev, next) => {
  currSlide.value = prev
}

const getSliderInstance = (splide) => {
  splideInstance.value = splide
}

const goTo = (idx) => {
  splideInstance.value.go(idx)
}
</script>

<style lang="scss">
.activation-lethality-slider {
  padding: 80px 0;
  overflow: hidden;

  @include mobile {
    padding: 40px 0;
  }

  .lines-container {
    position: absolute;
    top: -80px;
    width: 100%;

    @include tablet-landscape(1) {
      display: none;
    }

    .lines {
      position: absolute;
      top: 0;
      z-index: 0;

      &.left {
        left: -50px;

        @include tablet-landscape(1) {
          left: -40px;
        }

        @include tablet-landscape(1) {
          left: -35px;
        }

        &:before {
          left: 30px;
        }

        &:after {
          left: 0;
        }

        span {
          left: 27px;
        }
      }

      &.right {
        right: -50px;

        @include tablet-landscape(1) {
          right: -40px;
        }

        @include tablet-landscape(1) {
          right: -35px;
        }

        &:before {
          right: 30px;
        }

        &:after {
          right: 0;
        }

        span {
          right: 27px;
        }
      }

      &:before,
      &:after,
      span {
        position: absolute;
        top: 0;
        display: block;
      }

      &:before,
      &:after {
        content: '';
        background-color: #E2E2E2;
      }

      &:before {
        width: 1px;
        height: 120px;
      }

      &:after {
        top: 50px;
        width: 373px;
        height: 1px;
      }

      span {
        top: 47px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        z-index: 1;
        border: 1px solid $black;
      }
    }
  }

  .container {

    @include tablet-landscape(1) {
      width: 100%;
    }
  }

  .slider-container {
    position: relative;
    background-color: $baby-blue;

    .page-indicator {
      position: absolute;
      top: 50px;
      left: calc(58% - 36px);
      display: flex;
      align-items: center;
      z-index: 1;

      @include tablet-landscape(1) {
        top: 20px;
        left: 3.5%;
      }
  
      .indicator-letter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-right: 17px;
        border-radius: 50%;
        font-size: 2.8rem;
        background: linear-gradient(90deg, #FFA308 -13.76%, #FF5F06 45.07%, #DF1A1A 99.99%);
        color: $white;

        @include mobile {
          width: 50px;
          height: 50px;
          font-size: 2rem;
        }
      }

      .indicator-content {
        
        @include tablet-landscape(1) {
          display: none;
        }
      }
    }

    .slide {
      display: flex;
      align-items: center;

      @include tablet-landscape(1) {
        flex-direction: column;
      }

      .image-container {
        width: 58%;
        border-right: 1px solid #85B8B8;

        @include tablet-landscape(1) {
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          padding: 70px 0 0;

          @include tablet-landscape(1) {
            padding: 0;
          }
        }
      }

      .slide-content-container {
        position: relative;
        flex: 1;
        padding: 0 5% 0 6%;

        @include tablet-landscape(1) {
          width: 90%;
          padding: 30px 0 0;
          margin: 0 auto;
        }

        .indicator-letter {
          position: relative;
          display: none;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 70px;
          margin: 0 0 30px;
          border-radius: 50%;
          font-size: 2.8rem;
          background: linear-gradient(90deg, #FFA308 -13.76%, #FF5F06 45.07%, #DF1A1A 99.99%);
          color: $white;

          @include tablet-landscape(1) {
            display: flex;
          }

          @include mobile {
            width: 50px;
            height: 50px;
            font-size: 2rem;
          }
        }

        .indicator-title {
          display: none;

          @include tablet-landscape(1) {
            display: block;
            margin: 0 0 60px;
          }
        }

        .text {
          margin: 20px 0 0;
        }
      }
    }

    .splide {

      @include tablet-landscape(1) {
        padding-top: 160px;
        padding-bottom: 60px;
      }
    }

    .splide__arrows {
      position: absolute;
      bottom: 30px;
      left: 90px;
      display: flex;
      padding: 17px;
      border-radius: 54px;
      background-color: $off-black;

      @media only screen and (max-width: 1200px) {
        bottom: 20px;
      }

      @include tablet-landscape(1) {
        top: 90px;
        bottom: initial;
        left: 50%;
        margin: auto;
        transform: translateX(-50%);
      }

      .numbers {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .num,
      .splide__arrow {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        margin-right: 5px;
        border-radius: 50%;

        @include tablet-landscape(1) {
          width: 40px;
          height: 40px;
        }
      }

      .num {
        position: relative;
        font-size: 2.4rem;
        border: 1px solid rgba(255, 255, 255, 0.50);
        overflow: hidden;
        color: $white;
        @include font-alt;
        @include transition;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, #FF5F06 2.78%, #FFA308 80.84%, #FFA308 100%);
          opacity: 0;
          @include transition;
        }

        &.active {
          border: 1px solid $orange;

          &:before {
            opacity: 1;
          }
        }

        &.label {
          width: auto;
          height: auto;
          font-size: 1.4rem;
          line-height: 1;
          text-align: center;
          padding: 7px 15px;
          border-radius: 54px;

          @include tablet-landscape(1) {
            font-size: 1.2rem;
          }
        }

        &:last-child {
          margin-right: 15px;
        }

        p {
          position: relative;
        }
      }

      .splide__arrow {
        opacity: 1;
        border: 1px solid $white;
        @include transition;

        &.splide__arrow--prev {
          transform: scaleX(-1);
        }

        &:disabled {
          cursor: default;
          opacity: 0.7;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .slide-progress-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 58%;

      @include tablet-landscape(1) {
        width: 100%;
      }

      .slide-progress {
        width: 0;
        height: 6px;
        background: linear-gradient(270deg, #FFF 0%, #FFA308 31.5%, #FF5F06 68.5%, #DF1A1A 100%);
        transform: scaleX(-1);
        @include transition;
      }
    }
  }
}
</style>
