<template>
  <spline-viewer :url="scene" ref="splineInstance"></spline-viewer>
</template>

<script setup>
import { ref, onBeforeMount, onMounted } from 'vue'

defineProps(['scene'])

const splineInstance = ref(null)

onBeforeMount(() => {
  const script = document.createElement('script');
  script.src = 'https://unpkg.com/@splinetool/viewer/build/spline-viewer.js';
  script.type = 'module';
  document.body.appendChild(script);
})

onMounted(() => {
  // splineInstance.value.dispose()

  console.log(splineInstance.value)
})
</script>

<style lang="scss">

</style>