<template>
  <section class="science-page-callout">
    <div class="container large">
      <div class="lines-container">
        <div class="lines left">
          <span></span>
        </div>
        <div class="lines right">
          <span></span>
        </div>
      </div>
      <div class="sci-pc-container">
        <div class="pc-image-container" ref="trigger">
          <div class="pc-image">
            <template v-if="data.page_callout_image_mobile && device == 'mobile'">
              <Imager :image="data.page_callout_image_mobile" :native="true" />
            </template>
            <template v-else>
              <Imager :image="data.page_callout_image" :native="true" />
            </template>
          </div>
          <div class="headline-cta-container">
            <div class="headline-cta" :class="{ 'has-indicator': data.page_callout_toggle }">
              <div class="page-indicator" v-if="data.page_callout_toggle">
                <div class="indicator-letter">{{ data.page_callout_letter }}</div>
                <div class="indicator-content">
                  <div class="indicator-subtitle text-smaller">
                    {{ data.page_callout_indicator_title}}
                  </div>
                </div>
              </div>
              <div class="headline h3" v-html="data.page_callout_headline" ref="headline"></div>
              <Button :link="data.page_callout_link" v-if="data.page_callout_link" />
            </div>
          </div>
        </div>
        <div class="pc-text-container" :class="{ 'has-slider': data.page_callout_include_slider }">
          <div class="pc-text-cols">
            <div class="lines">
              <span></span>
            </div>
            <div class="col editor-formatting" :class="{ 'h4': data.page_callout_include_slider }"
              v-html="data.page_callout_col1_text"></div>
            <div class="col editor-formatting" v-html="data.page_callout_col2_text"></div>
          </div>
          <div class="pc-slider" v-if="data.page_callout_include_slider">
            <div class="filter slider-filter">
              <div class="filter-wrapper">
                <div v-for="(item, index) in data.page_callout_slider" :key="index" class="category"
                  :class="{ active: index == activeTab }" @click="updateFilter(index)">
                  <p>{{ item.page_callout_slide_tab_label }}</p>
                </div>
              </div>
            </div>
            <div class="pc-slider-container">
              <div class="slide" v-for="(slide, index) in data.page_callout_slider" :key="index">
                <Imager :image="slide.page_callout_slide_image" :native="true" v-if="index == activeTab" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "SciencePageCalloutComponent",
    "CLASSNAME": "science-page-callout",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import Imager from '@/components/utility/Imager.vue'
import Button from '@/components/utility/Button.vue'
import { ref, onBeforeUnmount, onMounted } from 'vue'
import useUtils from '@/components/composables/useUtils.js'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const { device } = useUtils()

const trigger = ref(null)
const headline = ref(null)
const activeTab = ref(0);

const updateFilter = (idx) => {
  activeTab.value = idx
}

let tl

onMounted(() => {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top+=30% bottom',
      end: 'bottom top',
      scrub: true,
    },
  }).to(headline.value, 1, {
    y: 0,
    ease: Linear.easeNone,
  })
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.science-page-callout {
  padding: 80px 0;
  overflow: hidden;

  @include mobile {
    padding: 40px 0;
  }

  .container {

    @include mobile {
      width: 100%;
    }
  }

  .lines-container {
    position: absolute;
    top: -80px;
    width: 100%;

    @include mobile {
      display: none;
    }

    .lines {
      position: absolute;
      top: 0;
      z-index: 0;

      &.left {
        left: -50px;

        @include tablet-landscape(1) {
          left: -40px;
        }

        @include mobile {
          left: -35px;
        }

        &:before {
          left: 30px;
        }

        &:after {
          left: 0;
        }

        span {
          left: 27px;
        }
      }

      &.right {
        right: -50px;

        @include tablet-landscape(1) {
          right: -40px;
        }

        @include mobile {
          right: -35px;
        }

        &:before {
          right: 30px;
        }

        &:after {
          right: 0;
        }

        span {
          right: 27px;
        }
      }

      &:before,
      &:after,
      span {
        position: absolute;
        top: 0;
        display: block;
      }

      &:before,
      &:after {
        content: '';
        background-color: rgba(0,0,0,0.1);
      }

      &:before {
        width: 1px;
        height: 120px;
      }

      &:after {
        top: 50px;
        width: 373px;
        height: 1px;
      }

      span {
        top: 47px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        z-index: 1;
        border: 1px solid $black;
      }
    }
  }

  .sci-pc-container {
    position: relative;
  }

  .pc-image-container {
    position: relative;
    width: 100%;

    .pc-image {
      position: relative;
      width: 100%;

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 70%;
        height: 100%;
        background-image: url('@/assets/img/pc-image-overlay.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

        @include tablet-landscape(1) {
          width: 100%;
          height: 115%;
          background-image: url('@/assets/img/pc-image-overlay-mobile.png');
        }

        @media only screen and (max-width: 625px) {
          height: 95%;
        }
      }

      .imager {
        width: 100%;
        
        .imager-container,
        .placeholder {
          max-width: 100% !important;
        }

        img {
          width: 100% !important;
          min-height: 500px;
          object-fit: cover;

          @include tablet-landscape(1) {
            height: 550px;
            min-height: 0;
          }
        }
      }
    }

    .headline-cta-container {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 0;
      left: 0;
      right: 0;
      width: 90%;
      height: 100%;
      padding: 60px 0;
      margin: auto;
      z-index: 1;

      @include tablet-landscape {
        padding: 40px 0;
      }

      .headline-cta {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 450px;
        height: 100%;

        @include tablet-landscape {
          max-width: 400px;
        }

        &.has-indicator {
          max-width: 500px;

          .headline {
            transform: translateY(0%) !important;
          }
        }

        .page-indicator {
          position: relative;
          left: -5%;
          display: flex;
          align-items: center;
          margin: 0 0 30px;

          .indicator-letter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            margin-right: 17px;
            border-radius: 50%;
            font-size: 2.8rem;
            background: linear-gradient(90deg, #FFA308 -13.76%, #FF5F06 45.07%, #DF1A1A 99.99%);
            color: $white;

            @include mobile {
              left: -3%;
              width: 50px;
              height: 50px;
              font-size: 2rem;
            }
          }
        }

        .headline {
          transform: translateY(-150%);

          @include tablet-landscape {
            transform: translateY(-100%);
          }

          @include tablet-landscape(1) {
            transform: translateY(0%) !important;
          }
        }

        .btn {
          align-self: flex-start;
          margin: 40px 0 0;
        }
      }
    }
  }

  .pc-text-container {
    padding: 0 9%;
    overflow: hidden;
    background-color: $baby-blue;

    &.has-slider {
      padding: 0 7%;
      background: linear-gradient(180deg, #C7FFFF 0%, #FFF 100%);

      @include mobile {
        padding: 0 5%;
      }

      .pc-text-cols {
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        padding-bottom: 0;

        .lines {
          left: -60px;

          @include mobile {
            left: -13px;
          }
        }

        .col {
          max-width: 500px;
        }
      }
    }

    .pc-text-cols {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 90%;
      max-width: 920px;
      padding: 105px 0;
      margin-left: auto;

      @include tablet-landscape {
        width: 100%;
        padding: 80px 0;
      }

      @include tablet-landscape(1) {
        padding: 50px 0;
      }

      @include mobile {
        flex-direction: column;
      }

      .lines {
        position: absolute;
        top: 0;
        left: -80px;
        z-index: 0;

        @include tablet-landscape {
          left: -40px;
        }

        @include tablet-landscape(1) {
          top: -20px;
          left: -20px;
        }

        &:before,
        &:after,
        span {
          position: absolute;
          top: 0;
          display: block;
        }

        &:before,
        &:after {
          content: '';
          background-color: #AADEDE;
        }

        &:before {
          width: 1px;
          height: 120px;
        }

        &:after {
          top: 50px;
          left: -42px;
          width: 373px;
          height: 1px;
        }

        span {
          top: 47px;
          left: -2.5px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          z-index: 1;
          border: 1px solid $black;
        }
      }

      .col {
        position: relative;
        width: 48%;

        @include mobile {
          width: 100%;

          &:not(:first-child) {
            margin: 20px 0 0;
          }
        }
      }
    }

    .pc-slider {
      margin: 100px 0 0;

      @include mobile {
        margin: 60px 0 0;
      }

      .slider-filter {

        @include mobile {
          display: flex;
        }

        .filter-wrapper {
          padding: 17px 15px;

          .category {
            font-size: 1.4rem;
            padding: 10px 14px;
            @include font-alt;

            @include mobile {
              font-size: 1.2rem;
            }
          }
        }
      }

      .pc-slider-container {
        margin: 60px 0 0;
      }
    }
  }
}
</style>
