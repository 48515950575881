<template>
  <section class="hero" ref="trigger">
    <div class="hero-bg" :class="data.hero_gradient_orientation.value">
      <picture>
        <source
          media="(max-width: 1024px)"
          :srcset="
            data.hero_image.sizes.large
              ? data.hero_image.sizes.large
              : data.hero_image.url
          " />
        <img
          ref="bg"
          :src="data.hero_image.url"
          :alt="data.hero_image.alt"
          :title="data.hero_image.alt" />
      </picture>
    </div>
    <div class="hero-content">
      <div class="headline h2" v-html="data.hero_headline" ref="headline"></div>
      <div ref="iconScroll" class="icon-scroll">
        <img src="@/assets/img/icon-scroll-down.svg" alt="">
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HeroComponent",
    "CLASSNAME": "hero",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import useIntroShown from '@/components/composables/useIntroShown.js'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const { introShown } = useIntroShown()

const trigger = ref(null)
const bg = ref(null)
const headline = ref(null)

let tlBg

const tlHeadline = gsap.timeline({
  paused: true,
  delay: 0.5,
})

const hasIntroShown = () => {
  if (introShown.value) {
    tlHeadline.play()
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 100)
  }
}

const parallax = () => {
  gsap.fromTo(
    tlBg.value,
    {
      y: -150,
    },
    {
      y: 150,
      scrollTrigger: {
        trigger: tlBg.value,
        scrub: true,
        start: 'top top',
        end: 'bottom top',
      },
    }
  )
}

const headlineTween = () => {
  tlHeadline.staggerTo( headline.value.children, 0.75, {
    clipPath: 'polygon(0 0, 100% 0, 100% 110%, 0 110%)',
    y: 0,
    delay: 0.15,
  }, 0.15)
}

onMounted(() => {

  tlBg = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top top',
      end: 'bottom top',
      scrub: true
    },
  })
  .to(bg.value, {
    y: -40,
    ease: Linear.easeNone,
  })

  parallax()
  headlineTween()
  hasIntroShown()
})

onBeforeUnmount(() => {
  tlBg.kill()
  tlHeadline.kill()
})
</script>

<style lang="scss">
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 950px;
  overflow: hidden;

  @include mobile {
    height: 100vh;
    min-height: 500px;
  }

  .hero-bg {
    position: absolute;
    width: 100%;
    height: 100%;

    &.top:after {
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('@/assets/img/gradient-cover.png');

      @include mobile {
        width: 100%;
      }
    }

    &.bottom:after {
      bottom: 0;
      height: 85%;
      width: 60%;
      background-image: url('@/assets/img/gradient-cover-bottom.png');

      @include mobile {
        width: 100%;
      }
    }

    &.none:after {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img {
      width: 100%;
      height: calc(100% + 40px);
      object-fit: cover;
    }
  }

  .hero-content {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    @include font-main-bold;

    .headline {
      >* {
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
        transform: translateY(100%);
      }
    }

    .icon-scroll {
      position: absolute;
      top: calc(100% + 30px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
