<template>
  <main class="single-team">
    <div class="container med">
      <div class="btn-container">
        <a href="/about" class="btn-gradient">Back to About Us</a>
      </div>
      <template v-if="member">
        <div class="member-container">
          <div class="photo-container">
            <div class="lines left">
              <span></span>
            </div>
            <ResponsiveImage v-if="member.featured_media" :native="true" :media-id="member.featured_media" />
          </div>
          <div class="info-container">
            <div class="info-head">
              <h1 class="name h3" v-html="member.title.rendered"></h1>
              <div v-if="member.acf.team_member_title" class="member-title h4" v-html="member.acf.team_member_title">
              </div>
            </div>
            <div v-if="member && member.content" class="member-bio editor-formatting" v-html="member.content.rendered">
            </div>
            <div v-if="member.acf.team_member_external_link" class="external">
              Read more:
              <a :href="member.acf.team_member_external_link" target="_blank">here</a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </main>
</template>

<script setup>
import { computed, ref, inject, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
import useUtils from '@/components/composables/useUtils.js'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const { decodeTitle } = useUtils()

const request = ref({
  type: 'team',
  slug: props.slug,
  showLoading: true,
})

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const getMember = async () => {
  await store.getSingleBySlug(request.value)
  if (member.value) {
    store.updateDocTitle({
      parts: [decodeTitle(member.value.title.rendered), store.site.name],
    })
  } else {
    router.replace('/404')
  }
}


onBeforeMount(async () => {
  await getMember()
})
</script>

<style lang="scss">
.single-team {
  position: relative;
  padding: 150px 0 120px;
  overflow: hidden;

  @include mobile {
    padding: 100px 0 40px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url('@/assets/img/bg-gradient-team.png');
    background-repeat: no-repeat;
  }

  .btn-container {
    display: flex;
    margin: 0 0 30px;

    @include mobile {
      justify-content: center;
    }
  }
  
  .member-container {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }

    .photo-container {
      position: relative;
      width: 35%;
      margin-right: 10%;
      
      @include mobile {
        width: 100%;
        margin: 0 0 70px;
      }

      .lines {
        position: absolute;
        top: -80px;
        right: 0;
        z-index: 0;

        @include mobile {
          top: initial;
          right: initial;
          left: -4%;
          bottom: 0;
        }
    
        &.left {
    
          &:before {
            left: 30px;
          }
    
          &:after {
            left: 0;
          }
    
          span {
            left: 27px;
          }
        }
    
        &:before,
        &:after,
        span {
          position: absolute;
          top: 0;
          display: block;
        }
    
        &:before,
        &:after {
          content: '';
          background: rgba(0, 0, 0, 0.20);
        }
    
        &:before {
          width: 1px;
          height: 120px;
        }
    
        &:after {
          top: 50px;
          width: 373px;
          height: 1px;
        }
    
        span {
          top: 47px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          z-index: 1;
          border: 1px solid $black;
        }
      }

      .responsive-image {
        width: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .info-container {
      flex: 1;
      max-width: 700px;

      .info-head {
        margin: 0 0 40px;

        .name {
          margin: 0 0 20px;
          color: $orange;
        }
      }
    }
  }
}
</style>
